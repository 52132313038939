<script setup
        lang="ts">
import { useScriptTag }           from '@vueuse/core';
import { onMounted, onUnmounted } from 'vue';

const props = withDefaults(defineProps<{
    chatId: string | null;
}>(), {});

const { load: chat_load, unload: chat_unload } = useScriptTag(
    '//eu.fw-cdn.com/12781688/764985.js',
    () => {
        if (props.chatId) {
            window.fcWidgetMessengerConfig.restoreId = props.chatId;
        }

        window.fcSettings = {
            onInit() {
                if (!props.chatId) {
                    window.fcWidget.user?.clear(() => {});
                }
            },
        };
    },
    {
        manual: true,
        async:  true,
        defer:  true,
    },
);

onMounted(async () => {
    window.fcWidgetMessengerConfig = {
        config: {
            eagerLoad:      false,
            headerProperty: {
                hideChatButton: false,
            },
        },
    };

    await chat_load();
});

onUnmounted(async () => {
    chat_unload();
});
</script>

<template>
    <p><!-- --></p>
</template>

<style lang="scss"
       scoped>
// nothing here
</style>
